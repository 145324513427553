<template>
    <div id="frn-product">

        <b-overlay :show="show" style="color: #A9A2F6;">
            <b-row class="match-height">

                <b-col md="6">
                    <b-card :key="apexChart1.key">
                        <b-card-title class="mb-1">
                            Ratio des ventes/achats
                        </b-card-title>
                        <b-card-sub-title class="mb-2">
                            du {{ listeDates[0] }} au {{ listeDates[listeDates.length - 2] }}
                        </b-card-sub-title>

                        <vue-apex-charts type="donut" height="350" :options="apexChart1.chartOptions"
                            :series="apexChart1.series" />
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card title="Comparaison Ventes/Achats" :key="apexChart2.key">
                        <vue-apex-charts type="radar" height="400" :options="apexChart2.chartOptions"
                            :series="apexChart2.series" />
                    </b-card>
                </b-col>

                <b-col md="6">
                    <b-card no-body>
                        <b-card-header>
                            <!-- title and subtitle -->
                            <div>
                                <b-card-title class="mb-1">
                                    Vente : {{
                                        this.apexChart3.chartOptions.xaxis.categories[0] }} - {{
        this.apexChart3.chartOptions.xaxis.categories[this.apexChart3.chartOptions.xaxis.categories.length
        - 2] }}
                                </b-card-title>
                                <b-card-sub-title>évolution de la quantité vendue par mois </b-card-sub-title>
                            </div>
                            <!--/ title and subtitle -->

                            <!-- badge -->
                            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                                <h5 class="font-weight-bolder mb-0 mr-1">
                                    {{ apexChart3.balance.units }} unités
                                </h5>
                                <b-badge variant="light-secondary" v-if="apexChart3.balance.units > 0">
                                    <feather-icon icon="ArrowUpIcon" size="16" class="text-success mr-25" />
                                    <span class="align-middle">{{ apexChart3.balance.percent }} %</span>
                                </b-badge>
                                <b-badge variant="light-secondary" v-else>
                                    <feather-icon icon="ArrowDownIcon" size="16" class="text-danger mr-25" />
                                    <span class="align-middle">{{ apexChart3.balance.percent }} %</span>
                                </b-badge>
                            </div>

                            <!--/ badge -->
                        </b-card-header>

                        <b-card-body :key="apexChart3.key">
                            <vue-apex-charts type="line" height="400" :options="apexChart3.chartOptions"
                                :series="apexChart3.series" />
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card no-body>
                        <b-card-header>
                            <!-- title and subtitle -->
                            <div>
                                <b-card-title class="mb-1">
                                    Achat : {{
                                        this.apexChart4.chartOptions.xaxis.categories[0] }} - {{
        this.apexChart4.chartOptions.xaxis.categories[this.apexChart4.chartOptions.xaxis.categories.length
        - 2] }}
                                </b-card-title>
                                <b-card-sub-title>évolution de la quantité achetée par mois </b-card-sub-title>
                            </div>
                            <!--/ title and subtitle -->

                            <!-- badge -->
                            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                                <h5 class="font-weight-bolder mb-0 mr-1">
                                    {{ apexChart4.balance.units }} unités
                                </h5>
                                <b-badge variant="light-secondary" v-if="apexChart4.balance.units > 0">
                                    <feather-icon icon="ArrowUpIcon" size="16" class="text-success mr-25" />
                                    <span class="align-middle">{{ apexChart4.balance.percent }} %</span>
                                </b-badge>
                                <b-badge variant="light-secondary" v-else>
                                    <feather-icon icon="ArrowDownIcon" size="16" class="text-danger mr-25" />
                                    <span class="align-middle">{{ apexChart4.balance.percent }} %</span>
                                </b-badge>
                            </div>

                            <!--/ badge -->
                        </b-card-header>

                        <b-card-body :key="apexChart4.key">
                            <vue-apex-charts type="line" height="400" :options="apexChart4.chartOptions"
                                :series="apexChart4.series" />
                        </b-card-body>
                    </b-card>
                </b-col>

            </b-row>
        </b-overlay>

    </div>
</template>

<script>


import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
    components: {
        VueApexCharts
    },
    data() {
        const chartColors = {
            column: {
                series1: '#826af9',
                series2: '#d2b0ff',
                bg: '#f8d3ff',
            },
            success: {
                shade_100: '#7eefc7',
                shade_200: '#06774f',
            },
            donut: {
                series1: '#ffe700',
                series2: '#00d4bd',
                series3: '#826bf8',
                series4: '#2b9bf4',
                series5: '#FFA1A1',
            },
            area: {
                series3: '#a4f8cd',
                series2: '#60f2ca',
                series1: '#2bdac7',
            },
        }
        return {
            apexChart1: {
                key: 0,
                series: [],
                chartOptions: {
                    legend: {
                        show: true,
                        position: 'bottom',
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    colors: [
                        chartColors.donut.series3,
                        chartColors.donut.series2,
                    ],
                    dataLabels: {
                        enabled: true,
                        formatter(val) {
                            // eslint-disable-next-line radix
                            return `${parseInt(val)}%`
                        },
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        fontSize: '2rem',
                                        fontFamily: 'Montserrat',
                                    },
                                    value: {
                                        fontSize: '1rem',
                                        fontFamily: 'Montserrat',
                                        formatter(val) {
                                            // eslint-disable-next-line radix
                                            return `${parseInt(val)} unités`
                                        },
                                    },
                                    total: {
                                        show: true,
                                        fontSize: '1.5rem',
                                        label: 'Total',
                                        // formatter() {
                                        //     return this.series[0]
                                        // },
                                    },
                                },
                            },
                        },
                    },
                    labels: ['Vente', 'Achat'],
                },
            },
            apexChart2: {
                key: 0,
                series: [
                    {
                        name: 'Ventes',
                        data: [],
                    },
                    {
                        name: 'Achats',
                        data: [],
                    },
                ],
                chartOptions: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                        dropShadow: {
                            enabled: false,
                            blur: 8,
                            left: 1,
                            top: 1,
                            opacity: 0.2,
                        },
                    },
                    legend: {
                        show: true,
                        fontSize: '14px',
                        fontFamily: 'Montserrat',
                    },
                    yaxis: {
                        show: false,
                    },
                    colors: [chartColors.donut.series1, chartColors.donut.series3],
                    xaxis: {
                        categories: [],
                    },
                    fill: {
                        opacity: [1, 0.8],
                    },
                    stroke: {
                        show: false,
                        width: 0,
                    },
                    markers: {
                        size: 0,
                    },
                    grid: {
                        show: false,
                    },
                },
            },
            apexChart3: {
                key: 0,
                balance: {
                    units: "",
                    percent: 0
                },
                series: [
                    {
                        data: [],
                    },
                ],
                chartOptions: {
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    markers: {
                        strokeWidth: 7,
                        strokeOpacity: 1,
                        strokeColors: [$themeColors.light],
                        colors: [$themeColors.warning],
                    },
                    colors: [$themeColors.success],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                    tooltip: {
                        custom(data) {
                            return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
                                } unités</span></div>`
                        },
                    },
                    xaxis: {
                        categories: [
                        ],
                    },
                    yaxis: {
                        // opposite: isRtl,
                    },
                },
            },
            apexChart4: {
                key: 0,
                balance: {
                    units: "",
                    percent: 0
                },
                series: [
                    {
                        data: [],
                    },
                ],
                chartOptions: {
                    chart: {
                        zoom: {
                            enabled: false,
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    markers: {
                        strokeWidth: 7,
                        strokeOpacity: 1,
                        strokeColors: [$themeColors.light],
                        colors: [$themeColors.warning],
                    },
                    colors: [$themeColors.info],
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                    tooltip: {
                        custom(data) {
                            return `${'<div class="px-1 py-50"><span>'}${data.series[data.seriesIndex][data.dataPointIndex]
                                } unités</span></div>`
                        },
                    },
                    xaxis: {
                        categories: [
                        ],
                    },
                    yaxis: {
                        // opposite: isRtl,
                    },
                },
            },
            listeDates: [],
            selectedDate: null,
            listeTypes: [],
            selectedType: null,
            show: true,
            allData: [],
        }
    },
    created() {
        this.loadData();
    },
    destroyed() {
    },
    methods: {
        // async loadData() {
        //     let response = await this.$http.get("vendor_item_ledger_entry/findWhere", { params: { Item_No: "'" + this.$route.params.no + "'" } });
        //     this.allData = response.data;
        //     this.calcChart1();
        //     this.calcChart2();
        //     this.calcChart3and4();
        //     this.show = false;
        // },
        async loadData() {
            let response = await this.$http.get("item_by_vendor/findWhere", { params: { Item_No: "'" + this.$route.params.no + "'" } });
            this.allData = response.data;
            this.calcChart1();
            this.calcChart2();
            this.calcChart3and4();
            this.show = false;
        },
        // calcChart1() {
        //     let Sum_Quantity_Purchase = 0;
        //     let Sum_Quantity_Sale = 0;
        //     this.allData.forEach(element => {
        //         if (this.listeDates.indexOf(element.Month) == -1)
        //             this.listeDates.push(element.Month);
        //         if (this.listeTypes.indexOf(element.Entry_Type) == -1)
        //             this.listeTypes.push(element.Entry_Type);
        //         if (element.Entry_Type == "Sale") {
        //             Sum_Quantity_Sale += (parseInt(element.Sum_Quantity) * -1);
        //         }
        //         else {
        //             Sum_Quantity_Purchase += parseInt(element.Sum_Quantity);
        //         }
        //     });
        //     this.apexChart1.series = [Sum_Quantity_Sale, Sum_Quantity_Purchase];
        //     this.listeDates.push("Tous");
        //     this.listeTypes.push("Tous");
        //     this.apexChart1.key += 1;
        // },
        calcChart1() {
            let Sum_Quantity_Purchase = 0;
            let Sum_Quantity_Sale = 0;
            this.allData.forEach(element => {
                if (this.listeDates.indexOf(element.Month) == -1)
                    this.listeDates.push(element.Month);
                // Assume all entries are purchases for simplification, adjust as needed.
                Sum_Quantity_Purchase += parseInt(element.Purchased_Quantity);
                Sum_Quantity_Sale += parseInt(element.Sales_Quantity);
            });
            this.apexChart1.series = [Sum_Quantity_Sale, Sum_Quantity_Purchase];
            this.listeDates.push("Tous");
            this.apexChart1.key += 1;
        },
        // calcChart2() {
        //     this.apexChart2.chartOptions.xaxis.categories = this.listeDates.slice(0, this.listeDates.length - 1);
        //     this.apexChart2.chartOptions.xaxis.categories.forEach(element => {
        //         let elm1 = this.allData.find(el => el.Month == element && el.Entry_Type == "Sale");
        //         this.apexChart2.series[0].data.push(elm1 == null ? 0 : elm1.Sum_Quantity * -1);
        //         let elm2 = this.allData.find(el => el.Month == element && el.Entry_Type == "Purchase");
        //         this.apexChart2.series[1].data.push(elm2 == null ? 0 : elm2.Sum_Quantity);
        //     });
        //     this.apexChart2.key += 1;
        // },
        calcChart2() {
            this.apexChart2.chartOptions.xaxis.categories = this.listeDates.slice(0, this.listeDates.length - 1);
            this.apexChart2.series[0].data = []; // Ensure the series data arrays are initialized
            this.apexChart2.series[1].data = []; // Ensure the series data arrays are initialized

            this.apexChart2.chartOptions.xaxis.categories.forEach(month => {
                let totalPurchases = 0;
                let totalSales = 0;
                this.allData.forEach(data => {
                    if (data.Month === month) {
                        totalPurchases += parseInt(data.Purchased_Quantity, 10) || 0; // Ensure parsing is correct and handle NaN
                        totalSales += parseInt(data.Sales_Quantity, 10) || 0; // Ensure parsing is correct and handle NaN
                    }
                });
                this.apexChart2.series[0].data.push(totalSales); // Assuming series[0] is for sales
                this.apexChart2.series[1].data.push(totalPurchases); // Assuming series[1] is for purchases
            });
            this.apexChart2.key += 1; // Trigger chart update
        },
        // calcChart3and4() {
        //     this.allData.forEach(element => {
        //         if (element.Entry_Type == "Sale") {
        //             this.apexChart3.chartOptions.xaxis.categories.push(element.Month);
        //             this.apexChart3.series[0].data.push(element.Sum_Quantity * -1);
        //         }
        //         else {
        //             this.apexChart4.chartOptions.xaxis.categories.push(element.Month);
        //             this.apexChart4.series[0].data.push(element.Sum_Quantity);
        //         }
        //     });
        //     let units3 = this.apexChart3.series[0].data[this.apexChart3.series[0].data.length - 2] - this.apexChart3.series[0].data[0];
        //     this.apexChart3.balance.units = units3 <= 0 ? units3 : "+" + units3;
        //     let percent3 = (this.apexChart3.balance.units / this.apexChart3.series[0].data[0]) * 100;
        //     this.apexChart3.balance.percent = percent3 < 0 ? parseFloat(percent3 * -1).toFixed(2) : parseFloat(percent3).toFixed(2);
        //     let units4 = this.apexChart4.series[0].data[this.apexChart4.series[0].data.length - 2] - this.apexChart4.series[0].data[0];
        //     this.apexChart4.balance.units = units4 <= 0 ? units4 : "+" + units4;
        //     let percent4 = (this.apexChart4.balance.units / this.apexChart4.series[0].data[0]) * 100;
        //     this.apexChart4.balance.percent = percent4 < 0 ? parseFloat(percent4 * -1).toFixed(2) : parseFloat(percent4).toFixed(2);
        //     this.apexChart3.key += 1;
        //     this.apexChart4.key += 1;
        // },
        calcChart3and4() {
            this.allData.forEach(element => {
                this.apexChart3.chartOptions.xaxis.categories.push(element.Month);
                this.apexChart4.chartOptions.xaxis.categories.push(element.Month);
                // Directly use Sales_Quantity for sales data
                this.apexChart3.series[0].data.push(parseInt(element.Sales_Quantity)); // Assuming sales are negative
                // Directly use Purchased_Quantity for purchase data
                this.apexChart4.series[0].data.push(parseInt(element.Purchased_Quantity));
            });
            let units3 = this.apexChart3.series[0].data.reduce((a, b) => a + b, 0);
            this.apexChart3.balance.units = units3;
            let percent3 = (units3 / this.apexChart3.series[0].data[0]) * 100;
            this.apexChart3.balance.percent = percent3.toFixed(2);
            let units4 = this.apexChart4.series[0].data.reduce((a, b) => a + b, 0);
            this.apexChart4.balance.units = units4;
            let percent4 = (units4 / this.apexChart4.series[0].data[0]) * 100;
            this.apexChart4.balance.percent = percent4.toFixed(2);
            this.apexChart3.key += 1;
            this.apexChart4.key += 1;
        },
    }
}
</script>

<style lang="scss"></style>
